import React, { useState, useEffect } from "react";
import PageLayout from "../components/PageLayout";
import styled from "styled-components";
import Button from "../components/Button";
import ClickButton from "../components/ClickButton";
import { notification } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveRouteDetails } from "../redux/actions/routeActions";
import { Trans } from "react-i18next";
import { graphql } from "gatsby";
import { api } from "../utilities/api";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PartnerInfo } from "../models/PartnerInfo";

export default function partners() {
  const dispatch = useDispatch();

  const goToTop = () => {
    if (mobile) {
      window.scrollTo({
        top: 960,
        behavior: "smooth"
      })
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [businessContact, setBusinessContact] = useState({
    companyName: "",
    companyWebSite: "",
    contactPersonsName: "",
    location: "",
    email: "",
    phoneNumber: "",
    country: "",
    notes: ""
  });

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [textPadding, setTextPadding] = useState("0px");
  const [mobile, setMobile] = useState(false);

  const { t } = useTranslation();

  const partnerSlideImages = [
    "/images/partners/logos/1.png",
    "/images/partners/logos/2.png",
    "/images/partners/logos/3.png",
    "/images/partners/logos/4.png",
    "/images/partners/logos/5.png",
    "/images/partners/logos/6.png",
    "/images/partners/logos/7.png",
    "/images/partners/logos/8.png",
    "/images/partners/logos/9.png",
    "/images/partners/logos/10.png",
    "/images/partners/logos/11.png",
    "/images/partners/logos/12.png",
    "/images/partners/logos/13.png",
    "/images/partners/logos/14.png",
    "/images/partners/logos/15.png",
    "/images/partners/logos/16.png",
    "/images/ilsc.png",
    "/images/sidekick.logo.png"
  ];

  const copyImages = [
    "/images/partners/composant1.png",
    "/images/partners/composant2.png",
    "/images/partners/composant3.png",
    "/images/partners/composant4.png",
    "/images/partners/composant5.png"
  ];

  useEffect(() => {
    dispatch(saveRouteDetails("partners") as any);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const handleMouseEnter = (e) => {
    e.currentTarget.style.boxShadow = 'inset 0 0 0 1000px #0093E9';
    e.currentTarget.style.height = '100%';
    e.currentTarget.children[0].style.marginTop = '0px';
    e.currentTarget.children[1].style.color = '#fff';
    e.currentTarget.children[1].style.opacity = 1;
  }

  const onClick = (e) => {
    if (e.currentTarget.style.height === '256px') {
      e.currentTarget.style.height = '100%';
      e.currentTarget.style.boxShadow = 'inset 0 0 0 1000px #0093E9';
      e.currentTarget.children[0].style.marginTop = '0px';
      e.currentTarget.children[1].style.color = '#fff';
      e.currentTarget.children[1].style.opacity = 1;
    } else {
      e.currentTarget.style.boxShadow = 'inset 0 0 0 0px #0093E9';
      e.currentTarget.style.height = '256px';
      e.currentTarget.children[0].style.marginTop = '62px';
      e.currentTarget.children[1].style.color = '#fff';
      e.currentTarget.children[1].style.opacity = 0;
    }
  }

  const handleMouseLeave = (e) => {
    e.currentTarget.style.height = '256px';      
    e.currentTarget.style.boxShadow = 'inset 0 0 0 0px #0093E9';
    e.currentTarget.children[0].style.marginTop = '62px';
    e.currentTarget.children[1].style.color = '#fff';
    e.currentTarget.children[1].style.opacity = 0;
  }

  const onClickSendEmail = () => {
    if (
      !businessContact.companyName ||
      !businessContact.contactPersonsName ||
      !businessContact.email ||
      !businessContact.phoneNumber
    ) {
      setShowErrorMsg(true);
      return;
    }
    
    api.sendEmail2({
      CompanyName: businessContact.companyName,
      CompanyWebsiteOrSocialMedia: businessContact.companyWebSite,
      ContactPersonName: businessContact.contactPersonsName,
      Location: businessContact.location,
      Email: businessContact.email,
      PhoneNumber: businessContact.phoneNumber,
      Country: businessContact.country,
      Notes: businessContact.notes,
      EmailType: "business"
    } as PartnerInfo)
      .then(() =>
          notification.success({ message: "Successful", description: "Email successfully sent." })
      )
      .catch(() => {
          notification.success({ message: "Error", description: "Our services is currently not available." });
      })
  }

  return (
    <PageLayout showPartnerNav>
      <title>Become a Partner!</title>
      <BGImageSectionWrapper>
        <Container>
          <Row>
            <Col md={true} sm={true} className="p-3">
              <BlueTitle className="mb-4">
                <Trans>Partnership to accelerate your business.</Trans>
              </BlueTitle>
              <Text>
                <Trans>
                  Since 2011, PhoneBox has partnered with school boards and enterprises across Canada, providing the best-quality wireless plans on 5G/LTE networks at the most affordable rates. As wireless services continue to evolve, PhoneBox has also expanded its services to the United States.
                </Trans>
              </Text>
              <Text>
                <Trans>
                  We understand that every company has unique demands and requirements, which is why we strive to provide flexibility and seamlessly integrate our services into your operations. Let's connect and work together to help your business grow with PhoneBox.
                </Trans>
              </Text>
              <VideoDiv>                        
                <video style={{ borderRadius: '5px' }} width={'100%'} controls poster={mobile ? '/images/partners/PreVideo1.png' : "/images/partners/PreVideo1WithPlayButton.jpg"}>                        
                  <source src="/videos/PhoneboxV3.mp4" type="video/mp4"></source>
                  Your browser does not support the video.
                </video>
              </VideoDiv>
              <PartnerStatistic>
                <div style={{width: 80, display: 'flex', justifyContent: 'center', marginRight: 12}}>
                  <PartnerStatisticImg src="/images/partners/active-customers.png"/>
                </div>
                <div>
                  <PartnerStatisticTitle>
                    <Trans>
                      50,000+
                    </Trans>
                  </PartnerStatisticTitle>
                  <PartnerStatisticText>
                    <Trans>
                      Active PhoneBox Customers
                    </Trans>
                  </PartnerStatisticText>
                </div>
              </PartnerStatistic>
              <PartnerStatistic>
                <div style={{width: 80, minWidth:'80px', display: 'flex', justifyContent: 'center', marginRight: 12}}>
                  <PartnerStatisticImg src="/images/partners/providing-mobile-services.png"/>
                </div>
                <div>
                  <PartnerStatisticTitle>
                    <Trans>
                      12 Years
                    </Trans>
                  </PartnerStatisticTitle>
                  <PartnerStatisticText>
                    <Trans>
                      Providing mobile services to international students
                    </Trans>
                  </PartnerStatisticText>
                </div>
              </PartnerStatistic>
              <PartnerStatistic>
                <div style={{width: 80, display: 'flex', justifyContent: 'center', marginRight: 12}}>
                  <PartnerStatisticImg src="/images/partners/education-and-travel.png"/>
                </div>
                <div>
                  <PartnerStatisticTitle>
                    <Trans>
                      500+
                    </Trans>
                  </PartnerStatisticTitle>
                  <PartnerStatisticText>
                    <Trans>
                      Partners in the education and travel sector
                    </Trans>
                  </PartnerStatisticText>
                </div>
              </PartnerStatistic>
            </Col>
            <Col lg={2} md={0}></Col>
            <Col md={true} sm={true} className="p-3">
              <IntroFormWrapper className="shadow-lg">
                <PartnerBodyWrapper>
                  <Title
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      fontSize: "19px"
                    }}
                  >
                    <Trans>Tell Us More About You</Trans>
                  </Title>
                  <div
                    style={{color: '#fff',
                            fontSize: '12px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: mobile ? "column" : "row",
                          }}
                  >
                    <div>
                      <Trans>Please fill out the form to</Trans>
                    </div>
                    <div>
                      <Trans>&nbsp;get in touch with us</Trans>
                    </div>
                  </div>
                  <PartnerInput>
                    <MyInput
                      placeholder={t("Company Name*")}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          companyName: e.target.value,
                        })
                      }
                    />
                  </PartnerInput>
                  <PartnerInput>
                    <MyInput
                      placeholder={t("Company Website or Social Media")}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          companyWebSite: e.target.value,
                        })
                      }
                    />
                  </PartnerInput>
                  <PartnerInput>
                    <MyInput
                      placeholder={t("Contact Person's Name*")}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          contactPersonsName: e.target.value,
                        })
                      }
                    />
                  </PartnerInput>
                  <PartnerInput>
                    <MyInput
                      placeholder={t("Location")}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          location: e.target.value,
                        })
                      }
                    />
                  </PartnerInput>
                  <PartnerInput>
                    <MyInput
                      placeholder={t("Email*")}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          email: e.target.value,
                        })
                      }
                    />
                  </PartnerInput>
                  <PartnerInput>
                    <MyInput
                      placeholder={t("Phone Number*")}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          phoneNumber: e.target.value,
                        })
                      }
                    />
                  </PartnerInput>
                  <div style={{ color: "#fff", fontSize: "18px", fontWeight: 600, textAlign: "center", }}>
                    <Trans>
                      Where would you like to use PhoneBox Service?
                    </Trans>
                  </div>
                  <PartnerInput style={{ marginBottom: 5 }}>
                    <MySelect
                      value={businessContact.country}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          country: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled selected>{t("Select a country")}</option>
                      <option value="Canada">{t("Canada")}</option>
                      <option value="US">{t("United States")}</option>
                      <option value="Canada & US">{t("Canada") + " & " + t("United States")}</option>
                    </MySelect>
                  </PartnerInput>
                  <div style={{ fontSize: '10px', color: '#fff', paddingLeft: 5 }}>
                    <Trans>* Please select a country from the dropdown.</Trans>
                  </div>
                  <PartnerInput>
                    <MyTextbox
                      placeholder={t("Explain a little bit about your company...")}
                      onChange={(e) =>
                        setBusinessContact({
                          ...businessContact,
                          notes: e.target.value,
                        })
                      }
                    />
                  </PartnerInput>
                  <IntroFormBtnWrapper>
                    <ClickButton
                      text="Send Info"
                      color="red"
                      buttonLength="200px"
                      onClick={() => onClickSendEmail()}
                    />
                    <ErrorMsg show={showErrorMsg}>
                      Please fill in all of the required fields.
                    </ErrorMsg>
                  </IntroFormBtnWrapper>
                </PartnerBodyWrapper>
              </IntroFormWrapper>
              
            </Col>
          </Row>
        </Container>
      </BGImageSectionWrapper>
      <BlueSectionWrapper >
        <Container>
          <div className="text-white text-center">
            <Trans>DON'T JUST TAKE OUR WORD FOR IT</Trans>
          </div>
          <Title className="mb-3 text-white text-center">
            <Trans>See What Our Partners Are Saying</Trans>
          </Title>
          <Row>
            <Col sm={12} md={6}>
              <OverviewCard>
                <img src="/images/partners/stars.png" style={{ width: 120, paddingBottom: 12 }}/>
                <Text style={{ color: '#fff' }}>
                  <Trans>
                    PhoneBox provides great and affordable phone plan options for our students, and their staff is friendly and knowledgeable. As a long-time partner, ILSC is happy to be working with PhoneBox.
                  </Trans>
                </Text>
                <Text style={{color: "#fff", fontWeight: 600, fontSize: 22 }}>ILSC</Text>
                <img src="/images/partners/ILSC.png"/>
              </OverviewCard>
            </Col>
            <Col sm={12} md={6}>
              <OverviewCard>
                <img src="/images/partners/stars.png" style={{ width: 120, paddingBottom: 12 }}/>
                <Text style={{ color: "#fff"}}>
                  <Trans>
                    We really are grateful for your service this year. It has made our international student's arrival much smoother together, specially with all the government regulations.
                  </Trans>
                </Text>
                <Text style={{color: "#fff", fontWeight: 600, fontSize: 22 }}>Muskoka Language International</Text>
                <img src="/images/partners/MLI.png"/>
              </OverviewCard>
            </Col>
          </Row>
        </Container>
      </BlueSectionWrapper>
      <SectionWrapper>
        <Container>
          <WhatWeOfferText>
            <Trans>WHAT WE OFFER:</Trans>
          </WhatWeOfferText>
          <Title className="text-center" style={{color: "#075068", paddingTop: textPadding}}>
            <Trans>Become a PhoneBox Partner</Trans>
          </Title>
          
          <OfferCardRow>
            <OfferCardCol>
              <OfferCard 
                filename={copyImages[0]}
                onMouseEnter={!mobile ? handleMouseEnter : null}
                onClick={mobile ? onClick : null}
                onMouseLeave={!mobile ? handleMouseLeave : null}
              >
                <OfferCardHeader>
                  <OfferCardTitle style={{ marginTop: 0 }}>
                    <div>
                      <Trans>Dedicated</Trans>
                    </div>
                    <div>
                      <Trans>Account Executive</Trans>
                    </div>
                  </OfferCardTitle>
                </OfferCardHeader>
                <WhiteText>
                  <Trans>
                    PhoneBox offers a comprehensive range of services. What makes us special is that one of our dedicated Account Executives will accompany you along the entire value chain from the first introduction call to successfully partnering with us. Nowadays, companies have to radically revolutionize themselves every few years to stay relevant. Our Account Executives are here to support you with that.
                  </Trans>
                </WhiteText>
              </OfferCard>
            </OfferCardCol>
            <OfferCardCol>
              <OfferCard 
                filename={copyImages[1]}
                onMouseEnter={!mobile ? handleMouseEnter : null}
                onClick={mobile ? onClick : null}
                onMouseLeave={!mobile ? handleMouseLeave : null}
              >
                <OfferCardHeader>
                  <OfferCardTitle>
                    <Trans>Free Shipping</Trans>
                  </OfferCardTitle>
                </OfferCardHeader>
                <WhiteText>
                  <Trans>
                    In a successful partnership, 1 + 1 is larger than 2. 
                    We have created a worldwide partner network that 
                    allows us to offer free SIM card shipping around the 
                    world. This applies to single SIM cards as well as 
                    bulk shipments. The motto is: No partner is too small. 
                    No partner is too large. As a partner-oriented 
                    company, our shipping team is here to ensure prompt 
                    and fast delivery of your SIM cards.
                  </Trans>
                </WhiteText>
              </OfferCard>
            </OfferCardCol>
          </OfferCardRow>

          <OfferCardRow>
            <OfferCardCol>
              <OfferCard 
                filename={copyImages[2]}
                onMouseEnter={!mobile ? handleMouseEnter : null}
                onClick={mobile ? onClick : null}
                onMouseLeave={!mobile ? handleMouseLeave : null}
              >
                <OfferCardHeader>
                  <OfferCardTitle>
                    <Trans>Marketing Materials</Trans>
                  </OfferCardTitle>
                </OfferCardHeader>
                <WhiteText>
                  <Trans>
                    Marketing is an essential way of expanding businesses and an important feature to invest in. We have you covered with all the marketing materials that you need to successfully promote mobile phone services to your clients. We regularly update our partners with monthly invoice newsletters. Furthermore, our Account Executives will provide full support and will guide you through our various co-branding options.
                  </Trans>
                </WhiteText>
              </OfferCard>
            </OfferCardCol>
            <OfferCardCol>
              <OfferCard 
                filename={copyImages[3]}
                onMouseEnter={!mobile ? handleMouseEnter : null}
                onClick={mobile ? onClick : null}
                onMouseLeave={!mobile ? handleMouseLeave : null}
              >
                <OfferCardHeader>
                  <OfferCardTitle>
                    <Trans>Partners' Bonus</Trans>
                  </OfferCardTitle>
                </OfferCardHeader>
                <WhiteText>
                  <Trans>
                    PhoneBox partners receive special benefits and advantages for promoting our services to their clients. Our Account Executives will provide you with all the necessary information regarding our flexible reward options. During the entire partnership with PhoneBox, our dedicated Account Executives will provide you with useful tips that will help you maximize your partner bonus.
                  </Trans>
                </WhiteText>
              </OfferCard>
            </OfferCardCol>
          </OfferCardRow>

          <OfferCardRowBottom>
            <OfferCardCol>
              <OfferCard 
                filename={copyImages[4]}
                onMouseEnter={!mobile ? handleMouseEnter : null}
                onClick={mobile ? onClick : null}
                onMouseLeave={!mobile ? handleMouseLeave : null}
              >
                <OfferCardHeader>
                  <OfferCardTitle>
                    <Trans>Customized Solutions</Trans>
                  </OfferCardTitle>
                </OfferCardHeader>
                <WhiteText>
                  <Trans>
                    Together with our partners, we are shaping wireless communication in a sustainable and future-oriented way. We believe that we can create a valuable and motivating partnership environment that focuses on the individual needs of partners and clients. Our solutions can be perfectly tailored to your unique needs.
                  </Trans>
                </WhiteText>
              </OfferCard>
            </OfferCardCol>
          </OfferCardRowBottom>
          <div className="text-center" style={{paddingTop: "100px"}}>
            <Button
              onClick={goToTop}
              color="red"
              text="Get in Touch"
            />
          </div>
        </Container>
      </SectionWrapper>
      <SectionWrapper>
        <Container>
          <Title style={{ textAlign: "center", margin: "60px 0px" }}>
            <Trans>Our Partners</Trans>
          </Title>
        </Container>
        <Container>
          <div className="slider">
            <div className="slide-track">
              {partnerSlideImages.map((src, i) => (
                <div className="slide" key={i}>
                  <img src={src} height="100" width="200" alt={`Partner Image ${i + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </Container>
      </SectionWrapper>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const SectionWrapper = styled.div`
  margin: 80px 0px;
  @media only screen and (max-width: 768px) {
    margin: 5px 20px;
  }
`;

const BGImageSectionWrapper = styled.div`
  margin: 0px 0px;
  padding: 80px 0px;
  background-image: url('/images/partners/fade-stock-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media only screen and (max-width: 768px) {
    padding: 40px 0px;
    margin: 5px 20px;
    background-size: auto 35%;
    background-position-y: top;
  }
`

const BlueSectionWrapper = styled.div`
  padding: 80px 0px;
  background-color: #075068;
  
`

const Title = styled.h3`
  font-weight: 700;
`;

const BlueTitle = styled.h3`
  color: #075068;
  font-weight: 700;
  font-size: 45px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const Text = styled.p``;

const WhiteText = styled.p`
  opacity: 0;
  color: #fff;
  transition: all 0.3s ease;
  margin-top: 60px;
  padding: 0px 14px 0px 14px;
`;

const IntroFormWrapper = styled.div`
  border-radius: 20px;
  background-color: #005068;
  box-shadow: 0 19px 38px rgba(0, 38, 108, 0.1),
    0 15px 12px rgba(0, 38, 108, 0.1);
  width: 100%;
  @media (max-width: 768px) {
    border-radius: 12px;
  }
`;

const IntroFormBtnWrapper = styled.div`
  text-align: center;
`;

const OverviewCard = styled.div`
  padding: 20px;
  height: 100%;
  color: #fff;
`;

const OfferCardRowBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @media (min-width: 1400px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media (max-width: 1200px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`

const OfferCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @media (min-width: 1400px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media (max-width: 1200px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`

const OfferCardCol = styled.div`
  width: 500px;
  padding-top: 4rem;

  @media (max-width: 1200px) {
    width: 450px;
  }

  @media (max-width: 992px) {
    width: 330px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const OfferCardHeader = styled.div`
  display: flex;
  flex-direction: col;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  position: absolute;
  margin-top: 62px;
  width: 400px;
  text-align: center;

  @media (min-width: 1200px) {
    width: 450px;
  }
  @media (max-width: 992px) {
    width: 278px;
  }
  @media (max-width: 767px) {
    width: 88%;
  }
`;

const OfferCard = styled.div<{ filename: string }>`
  height: 256px;
  min-height: 256px;
  padding: 25px;
  border-radius: 20px;
  width: 100%;
  background-color: #0093E9;
  background-image: url(${props => props.filename}); 
  background-size: cover;
  color: #fff;
  transition: all 0.3s ease;
  position: relative;

  @media (max-width: 992px) and (min-width: 767px) {
    height: 296px;
  }

  @media (max-width: 768px) {
    border-radius: 13px;
  }
`;

const OfferCardTitle = styled.div`
  color: #fff;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
`;


const PartnerInput = styled.div`
  margin: 20px 0px;
`;

const PartnerBodyWrapper = styled.div`
  width: 100%;
  padding: 40px 40px;
  font-size: 16px;
`;

const MySelect = styled.select`
  width: 100%;
  outline: none;
  border: 0px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  font-size: 12px;  
`

const MyTextbox = styled.textarea`
  width: 100%;
  outline: none;
  border: 0px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  font-size: 12px;  
`

const MyInput = styled.input`
  width: 100%;
  outline: none;
  border: 0px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  font-size: 12px;

  @media (max-width: 768px) {
    border-radius: 6px;
  }
`;

const PartnerStatistic = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
`

const PartnerStatisticImg = styled.img`
  padding: 16px;
  height: 68px;
`

const PartnerStatisticTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #333333;
`

const PartnerStatisticText = styled.div`
  color: #333333;
  font-size: 12px;
`

const ErrorMsg = styled.div<{ show: boolean }>`
  padding-top: 12px;
  color: red;

  display: ${props => props.show ? "block" : "none"};
`

const VideoDiv = styled.div`
  border-radius: 15px;
  // height: 150px;

  // @media (max-width: 992px) {
  //  height: 300px;
  // }

   @media (min-width: 992px) {
    height: 300px;
   }
`

const WhatWeOfferText = styled.div`
   text-align: center;
   padding-bottom: 1rem;
   color: #075068;

   @media (max-width: 768px) {
    padding-top: 2.5rem;
   }
`